import React from "react"
import cx from "classnames"

import styles from "./Hero.module.scss"
import { Holder } from "components/holder/Holder"

export const HeroMini = props => (
  <div className={cx(styles.wrapper, styles.heroMini)}>
    <Holder className={styles.holder}>
      {props.subtitle && <h3 className={styles.subtitle}>{props.subtitle}</h3>}
      <h1 className={styles.title}>{props.title}</h1>
      {props.text && <p className={styles.text}>{props.text}</p>}
      {props.button && <div className={styles.button}>{props.button}</div>}
      {props.extra && <p className={styles.extra}>{props.extra}</p>}
    </Holder>
  </div>
)
