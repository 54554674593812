import React, { useEffect, useState } from "react"
import cx from "classnames"
import Plx from "react-plx"

import styles from "./Hero.module.scss"
import { Holder } from "components/holder/Holder"

const plxTitle = [
  {
    start: "25vh",
    end: "60vh",
    easing: "ease",
    properties: [
      {
        startValue: 0,
        endValue: -5,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
]

const plxSubtitle = [
  {
    start: "25vh",
    end: "60vh",
    easing: "ease",
    properties: [
      {
        startValue: 0,
        endValue: -5,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
]

const plxButton = [
  {
    start: "25vh",
    end: "60vh",
    easing: "ease",
    properties: [
      {
        startValue: 0,
        endValue: -8,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
]

const BackgroundElements = () => {
  return (
    <>
      <div className={styles.elements}>
        <div className={styles.object_1} />
        <div className={styles.object_2} />
        <div className={styles.object_4} />
        <div className={styles.object_5} />
        <div className={styles.object_6} />
        <div className={styles.object_7} />
        <div className={styles.object_8} />
        <div className={styles.object_9} />
      </div>
      <div className={styles.lines}>
        <div className={styles.line_1} />
        <div className={styles.line_2} />
        <div className={styles.line_3} />
      </div>
    </>
  )
}

export const Hero = ({
  isIndex,
  isCenter,
  isMantra,
  fullscreenWithFooter,
  fullscreen,
  className,
  topImage,
  title,
  subtitle,
  button,
  animationsEnabled,
  showBackgroundElements,
}) => {
  const [freeze, setFreeze] = useState(true)

  useEffect(() => {
    setFreeze(false)

    return () => {
      setFreeze(true)
    }
  }, [])

  return (
    <div
      className={cx(
        styles.wrapper,
        {
          [styles.heroFullscreen]: fullscreen,
          [styles.heroFullscreenWithFooter]: fullscreenWithFooter,
          [styles.mantra]: isMantra,
          [styles.index]: isIndex,
          [styles.isCenter]: isCenter,
        },
        className
      )}
    >
      <Holder
        className={cx(styles.holder, {
          [styles.withTopImage]: !!topImage,
        })}
      >
        {topImage && <div className={styles.topImage}>{topImage}</div>}
        <div className={styles.content}>
          <Plx
            parallaxData={plxTitle}
            freeze={freeze}
            disabled={!animationsEnabled}
          >
            <h1 className={styles.title}>{title}</h1>
          </Plx>
          <Plx
            parallaxData={plxSubtitle}
            freeze={freeze}
            disabled={!animationsEnabled}
          >
            <h4 className={cx(styles.subtitle, "serif")}>{subtitle}</h4>
          </Plx>
          <Plx
            parallaxData={plxButton}
            freeze={freeze}
            disabled={!animationsEnabled}
          >
            {button && <div className={styles.button}>{button}</div>}
          </Plx>
        </div>

        {showBackgroundElements && <BackgroundElements />}
      </Holder>
    </div>
  )
}

Hero.defaultProps = {
  showBackgroundElements: false,
  animationsEnabled: true,
}
